import React, {Component} from "react";
import {FiX, FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy'


class HeaderThree extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
            window.scrollTo(window.scrollX, window.scrollY + 1);
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() {
    }

    navigateTo(myRef) {
        const elmnt = document.getElementById(myRef);
        elmnt.scrollIntoView();
        this.CLoseMenuTrigger();
    }

    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const {color = 'default-color'} = this.props;

        const {t} = this.props;

        return (
            <header data-aos="fade-in" data-aos-duration="400" data-aos-delay="400" data-aos-once="true"
                    data-aos-easing="ease-in-sine" className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center justify-content-between w-100">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                <img className="default-logo" src="/assets/images/logo/tsc_logo.png" alt="Tatran"/>
                                <img className="sticky-header-logo" src="/assets/images/logo/tsc_logo.png"
                                     alt="Tatran"/>
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu"
                                       items={['about', 'program', 'service', 'visitUs', 'application', 'contact']}
                                       currentClassName="is-current" offset={-200}>
                                <li>
                                    <a onClick={() => this.navigateTo('about')}>{t("menu.item1")}</a>
                                </li>
                                <li>
                                    <a onClick={() => this.navigateTo('program')}>{t("menu.item2")}</a>
                                </li>
                                <li>
                                    <a onClick={() => this.navigateTo('service')}>{t("menu.item3")}</a>
                                </li>
                                <li>
                                    <a onClick={() => this.navigateTo('visitUs')}>
                                        {t("menu.item4")}
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => this.navigateTo('application')}>{t("menu.item5")}</a>
                                </li>
                                <li>
                                    <a onClick={() => this.navigateTo('contact')}>{t("menu.item6")}</a>
                                </li>
                                <li className="language-switch">
                                    <span className={this.props.language === 'cs' ? 'active' : ''}
                                          onClick={this.props.changeToCs.bind()}>CS</span>
                                    <span> / </span>
                                    <span className={this.props.language === 'en' ? 'active' : ''}
                                          onClick={this.props.changeToEn.bind()}>EN</span>
                                </li>
                            </Scrollspy>
                        </nav>
                        <div className="hide-mobile"></div>
                    </div>
                    <div className="header-right">
                        {/*Start Humberger Menu*/}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu/></span>
                        </div>
                        {/*End Humberger Menu*/}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX/></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderThree;
