import React, {Component} from "react";

class Trainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            position: this.props.position,
            image: this.props.image,
        }
    }

    render() {
        const {name, position, image} = this.state;

        return (
            <div data-aos="fade-in" data-aos-duration="400" data-aos-once="true"
                 data-aos-easing="ease-in-out" className={!name ? 'trainer-segment hide-mobile' : 'trainer-segment'}>
                {image ? <img className="trainer-image" src={`/assets/images/trainers2/${image}`} loading="lazy"
                              alt={name}/> : ''}
                <p className="trainer-name">{name}</p>
                <p className="trainer-position">{position}</p>
            </div>

        )
    }
}

export default Trainer;
