import React, {Component} from 'react';
import {FaInstagram, FaFacebookF, FaYoutube} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF/>, link: 'https://www.facebook.com/tatranstresovice'},
    {Social: <FaInstagram/>, link: 'https://www.instagram.com/tatranstresovice/'},
    {Social: <FaYoutube/>, link: 'https://www.youtube.com/user/tatranstresovice'},
]

class FooterTwo extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <div className="footer-style-2 ptb--30 bg_color--10">
                {/* Start COntact Area */}

                <div id="contact" className="container contact-container">
                    <div className="row row--35">
                        <div className="col-lg-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">
                                        {t("contact.need_help")}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h4>{t("contact.title")}</h4>
                            <p>
                                {t("contact.more_info")}
                            </p>
                            <a className="pt--15" href="mailto:martin.panek@tatran.cz">martin.panek@tatran.cz</a>
                        </div>
                    </div>
                </div>

                {/* End COntact Area */}

                <div className="wrapper plr--50 plr_sm--20">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="inner">
                                <div className="logo text-center mb_sm--20">
                                    <a href="/">
                                        <img src="/assets/images/logo/logo-white.png" alt="Tatran Logo"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-6 col-12">
                            <div className="links">
                                <li><a href="#about">{t("menu.item1")}</a></li>
                                <li><a href="#program">{t("menu.item2")}</a></li>
                                <li><a href="#service">{t("menu.item3")}</a></li>
                                <li><a href="#visitUs">{t("menu.item4")}</a></li>
                                <li><a href="#application">{t("menu.item5")}</a></li>
                                <li><a href="#contact">{t("menu.item6")}</a></li>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <h4>{t("contact.do_not_miss_anything")}</h4>
                                    <p className="more-info">
                                        Více informací o Tatranu Střešovice: <a
                                        href="https://www.tatranflorbal.cz/">www.tatranflorbal.cz</a>
                                    </p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="inner text-center">
                                        <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                            {SocialShare.map((val, i) => (
                                                <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pt--40">
                            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                                <div className="text text-center copyright">
                                    <p>Copyright © 2024</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FooterTwo;
