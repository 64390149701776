import React, {Component} from "react";


class ProgramExpandPanel extends Component {
    state = {
        isOpen: false
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            title: this.props.title,
            subtitle: this.props.subtitle,
            description: this.props.description,
            descriptionExpand: this.props.descriptionExpand,
        }
    }

    onHandleVisibility = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        const {title, subtitle, description, descriptionExpand} = this.state;

        const {t} = this.props;

        return (
            <div data-aos="slide-up" data-aos-duration="400" data-aos-once="true"
                 data-aos-easing="ease-in-out" className="row row--35 program-expand-panel">
                <div className="col-6">
                    <h1>{t(title)}</h1>
                    <p className="subtitle">{t(subtitle)}</p>
                </div>
                <div className="col-6 detail">
                    <div className="description">
                        <p>{t(description)}</p>
                        {this.state.isOpen ? <p>{t(descriptionExpand)}</p> : null}
                    </div>
                    <div className="switch">
                        {this.state.isOpen ?
                            <svg onClick={this.onHandleVisibility} width="37.61" height="37.609" viewBox="0 0 37.61 37.609">
                                <g id="Group_188" data-name="Group 188" transform="translate(-1359 -13623)">
                                    <path id="Subtraction_1" data-name="Subtraction 1"
                                          d="M18.8,37.609a18.809,18.809,0,0,1-7.32-36.131A18.81,18.81,0,0,1,26.124,36.132,18.687,18.687,0,0,1,18.8,37.609ZM12.14,16.032v5.639H25.468V16.032Z"
                                          transform="translate(1359.001 13623)" fill="#fff"/>
                                </g>
                            </svg> :
                            <svg onClick={this.onHandleVisibility} width="37.609" height="37.609"
                                 viewBox="0 0 37.609 37.609">
                                <path id="Icon_awesome-plus-circle" data-name="Icon awesome-plus-circle"
                                      d="M19.367.563a18.8,18.8,0,1,0,18.8,18.8A18.8,18.8,0,0,0,19.367.563ZM30.286,21.49a.913.913,0,0,1-.91.91H22.4v6.976a.913.913,0,0,1-.91.91H17.244a.913.913,0,0,1-.91-.91V22.4H9.358a.913.913,0,0,1-.91-.91V17.244a.913.913,0,0,1,.91-.91h6.976V9.358a.913.913,0,0,1,.91-.91H21.49a.913.913,0,0,1,.91.91v6.976h6.976a.913.913,0,0,1,.91.91Z"
                                      transform="translate(-0.563 -0.563)" fill="#fff"/>
                            </svg>}
                    </div>
                </div>
                <div className="col-12">
                    <div className="divider"></div>
                </div>
            </div>

        )
    }
}

export default ProgramExpandPanel;
