import React, {useEffect, useState} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp,} from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import ProgramExpandPanel from "../elements/program-panel/ProgramExpandPanel";
import Trainer from "../elements/trainer/Trainer";
import ServiceBackground from "../elements/service-background/ServiceBackground";
import Application from "../elements/application/Application";
import Rellax from "rellax";
import {useTranslation} from "react-i18next";
import "../translations/i18n";
import i18n from "i18next";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'TATRAN STŘEŠOVICE',
        title: '',
        description: '',
        buttonText: 'Jedinečný letní kemp na té nejvyšší úrovni!',
        buttonLink: ''
    }
]

const ProgramList = [
    {
        title: 'program.list_item1.title',
        subtitle: 'program.list_item1.subtitle',
        description: 'program.list_item1.description',
        descriptionExpand: 'program.list_item1.descriptionExpand',
    },
    {
        title: 'program.list_item2.title',
        subtitle: 'program.list_item2.subtitle',
        description: 'program.list_item2.description',
        descriptionExpand: 'program.list_item2.descriptionExpand',
    }
]

const TrainersList = [
    {
        name: 'Martin Pánek',
        position: 'KOORDINÁTOR',
        image: 'Martin_Panek_Koordinator.png',
    },
    {
        name: 'Martin Pretl',
        position: 'TRENÉR',
        image: 'Martin_Pretl_trener.png',
    },
    {
        name: 'Barbora Husková',
        position: 'TRENÉRKA',
        image: 'Barbora_Huskova_trenerka.png',
    },
    {
        name: 'Lev Kliment',
        position: 'TRENÉR',
        image: 'Lev_Kliment_Trener_golmanu.png', // GAP
    },
    {},
    {
        name: 'Filip Píše',
        position: 'TRENÉ',
        image: 'Filip_Pise_trener.png',
    },
    {
        name: 'Petr Šotola',
        position: 'TRENÉR',
        image: 'Petr_Sotola_trener_Golmanu.png',
    },
    {
        name: 'Vojtěch Kún',
        position: 'TRENÉR',
        image: 'Vojtech_Kun_trener.png',
    },
    {},
    {},
    {
        name: 'Adéla Kramperová',
        position: 'FYZIOTERAPEUTKA',
        image: 'Adela_Kramperova_Fyzioterapeutka.png',
    },
    {
        name: 'Václav Bechyňák',
        position: 'FYZIOTERAPEUT',
        image: 'Vaclav_Bechynak_fyzioterapeut.png',
    },
]

const BackgroundsList = [
    {},
    {
        place: 'AREÁL TATRAN STŘEŠOVICE',
        activity: 'Florbal',
        image: 'Hala.jpeg',
    },
    {
        place: 'AREÁL TATRAN STŘEŠOVICE',
        activity: 'Umělka',
        image: 'Venkovni_hriste.jpeg',
    },
    {
        place: 'AREÁL PETYNKA',
        activity: 'Koupaliště',
        image: 'koupaliste.jpg',
    },
    {
        place: 'AREÁL TATRAN STŘEŠOVICE',
        activity: 'Regenerace',
        image: 'Posilovna.jpeg',
    },
    {
        place: 'AREÁL TATRAN STŘEŠOVICE',
        activity: 'Ubytování',
        image: 'Jidelna.jpeg',
    },
]

const ApplicationList = [
    {
        date: '30. 6. - 5. 7. 2025',
        description: 'application.item1.description',
        descriptionItems: [
            {
                text: 'application.item1.description_item1',
            },
            {
                text: 'application.item1.description_item2',
            },
            {
                text: 'application.item1.description_item3',
            },
            {
                text: 'application.item1.description_item4',
            }
            ,
            {
                text: 'application.item1.description_item5',
            }
            ,
            {
                text: 'application.item1.description_item6',
            },
            {
                text: 'application.item1.description_item7',
            }
        ]
    },
    {
        date: '21. 7. - 26. 7. 2025',
        description: 'application.item2.description',
        descriptionItems: [
            {
                text: 'application.item2.description_item1',
            },
            {
                text: 'application.item2.description_item2',
            },
            {
                text: 'application.item2.description_item3',
            },
            {
                text: 'application.item2.description_item4',
            }
            ,
            {
                text: 'application.item2.description_item5',
            }
            ,
            {
                text: 'application.item2.description_item6',
            },
            {
                text: 'application.item2.description_item7',
            }
        ]
    },
    {
        date: '28. 7. - 1. 8. 2025',
        description: 'application.item3.description',
        descriptionItems: [
            {
                text: 'application.item3.description_item1',
            },
            {
                text: 'application.item3.description_item2',
            },
            {
                text: 'application.item3.description_item3',
            },
            {
                text: 'application.item3.description_item4',
            }
            ,
            {
                text: 'application.item3.description_item5',
            }
            ,
            {
                text: 'application.item3.description_item6',
            },
            {
                text: 'application.item3.description_item7',
            }
        ]
    },
]

const PartnersList = [
    {image: 'Praha.svg', name: 'Praha', width: 140},
    {image: 'ESA.svg', name: 'ESA', width: 140},
    {image: 'Praha6.svg', name: 'Praha 6', width: 140},
    {image: 'Unihoc.svg', name: 'Unihoc', width: 140},
    {image: 'Florbalexpert.svg', name: 'Florbal expert', width: 140},
    {image: 'Logisteed.svg', name: 'Logisteed', width: 140},
    {image: 'Isostar.svg', name: 'Isostar', width: 140},
    {image: 'PSN.svg', name: 'PSN', width: 140},
    {image: 'HDT.svg', name: 'HDT', width: 140},
    {image: 'HTEST.svg', name: 'HTEST', width: 140},
    {image: 'Kastomi.svg', name: 'Kastomi', width: 140},
    {image: 'GYMSTORY.svg', name: 'GYMSTORY', width: 140},
    {image: 'Ceskavoda.svg', name: 'Ceskavoda', width: 140},
    {image: 'Ceskavoda.svg', name: 'Ceska voda', width: 140},
    {image: 'Raiff.svg', name: 'Raiffeisen leasing', width: 140},
    /*{image: 'praha.png', name: 'Praha', width: 60},
    {image: 'sestka-obchod.png', name: 'Šestka obchodní dům', width: 60},
    {image: 'proti-proudu.png', name: 'Proti proudu', width: 60},
    {image: 'spin_radio.png', name: 'Spin radio', width: 70},
    {image: 'sestka.png', name: 'Městská část Praha 6', width: 70},
    {image: 'esa_logistika.png', name: 'Esa logistika'},
    {image: 'psn.png', name: 'PSN'},
    {image: 'unihoc.png', name: 'Unihoc'},
    {image: 'petynka.png', name: 'Petynka'},
    {image: 'tarkett.png', name: 'Tarkett'},
    {image: 'anglicky-resort.png', name: 'Anglický resort'},
    {image: 'uneticky-pivovar.png', name: 'Unětický pivovar'},
    {image: 'xps-network.png', name: 'XPS Network'},
    {image: 'penco.png', name: 'Penco'},
    {image: 'florbal-expert.png', name: 'Florbal expert', width: 70},
    {image: 'schindler-seko-architects.png', name: 'Schindler Seko Architects'},
    {image: 'ko.png', name: 'KO', width: 60},
    {image: 'vsfs.png', name: 'VSFS'},
    {image: 'raiffeisen-leasing.png', name: 'Raiffeisen leasing'},
    {image: 'mcdavid.png', name: 'MCDAVID'},*/
]

const PortfolioLanding = () => {
    const {t} = useTranslation();

    const [language, setLanguage] = useState('cs');

    function changeTranslation(language) {
        return function () {
            setLanguage(language);
            i18n.changeLanguage(language);
        };
    }

    function navigateTo(myRef) {
        return function () {
            const elmnt = document.getElementById(myRef);
            elmnt.scrollIntoView();
        };
    }

    useEffect(() => {
        new Rellax(".rellax--trex-animate", {
            speed: 0.3,
            center: true,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".rellax--about-logo-animate", {
            speed: -0.35,
            center: true,
            wrapper: null,
            round: true,
            vertical: true,
            horizontal: false
        });
        new Rellax(".rellax--services-left-animate", {
            speed: 0,
            vertical: true,
            horizontal: false,
            round: true,
            center: true,
        });
        new Rellax(".rellax--services-middle-animate", {
            speed: 0.5,
            vertical: true,
            horizontal: false,
            round: true,
            center: true,
        });
        new Rellax(".rellax--services-right-animate", {
            speed: 0,
            vertical: true,
            horizontal: false,
            round: true,
            center: true,
        });
        new Rellax(".rellax--why-tcs-image-left-animate", {
            speed: -0.35,
            vertical: true,
            horizontal: false,
            round: true,
            center: true,
        });
        new Rellax(".rellax--why-tcs-image-middle-animate", {
            speed: -0.2,
            vertical: true,
            horizontal: false,
            round: true,
            center: true,
        });
        new Rellax(".rellax--why-tcs-image-right-animate", {
            speed: 0.5,
            vertical: true,
            horizontal: false,
            round: true,
            center: true,
        });
    }, []);

    return (
        <div>
            <Helmet pageTitle="Tatran Summer camp"/>
            <HeaderThree homeLink="/" logo="symbol-dark" color="color-white" t={t} language={language}
                         changeToCs={changeTranslation('cs')} changeToEn={changeTranslation('en')}/>

            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    <div className="home-container-background"></div>
                    <div className="image-overlay"></div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="container home-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={`inner text-left`}>
                                        <img className="title-logo" src="/assets/images/logo/SUMMERCAMP_logo.png"
                                             alt="Summer Camp"/>
                                        <div data-aos="fade-in" data-aos-duration="400" data-aos-delay="450"
                                             data-aos-easing="ease-in-sine" className="slide-btn mt--30"><a
                                            className="btn-default btn-border btn-opacity"
                                            onClick={navigateTo('application')}>{t("title_btn")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span className="subtitle">{t("about.title")}</span>
                                            <h2 className="title">{t("about.subtitle1")} <br/> {t("about.subtitle2")}
                                            </h2>
                                            <p className="description mt_dec--20">
                                                {t("about.description1")}
                                            </p>
                                            <p className="description mt_dec--20">
                                                {t("about.description2")}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="about-images">
                                        <img className="rellax--trex-animate about-image about-trex"
                                             src="/assets/images/about/about-trex_2.png"
                                             loading="lazy" alt="AboutTrex"/>
                                        <img className="about-image about-1" src="/assets/images/about/about-1_2.jpg"
                                             loading="lazy" alt="About1"/>
                                        <div className="about-main-images">
                                            <img className="about-image" src="/assets/images/about/about-2_2.jpg"
                                                 loading="lazy" alt="About2"/>
                                            <img className="about-image" src="/assets/images/about/about-4_2.jpg"
                                                 loading="lazy" alt="About4"/>
                                        </div>
                                        <img className="about-image about-3" src="/assets/images/about/about-3_2.jpg"
                                             loading="lazy" alt="About3"/>
                                        <img className="rellax--about-logo-animate about-image about-logo"
                                             src="/assets/images/about/about-logo_2.png"
                                             loading="lazy" alt="AboutLogo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

            {/* Start Program Area */}
            <div id="program" className="fix">
                <div className="program-area ptb--120 bg_color--primary">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title border-bottom">
                                            <span className="subtitle">{t("program.title")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row--35 program-area-title">
                                <div className="col-lg-6">
                                </div>
                                <div className="col-lg-6">
                                    <h1 className="title">
                                        {t("program.subtitle1")} <br/> {t("program.subtitle2")}
                                        <br/> {t("program.subtitle3")}
                                    </h1>
                                </div>
                                <div className="col-lg-12">
                                    <div className="divider"/>
                                </div>
                            </div>
                            {ProgramList.map((value, index) => (
                                <ProgramExpandPanel t={t} key={index} title={value.title}
                                                    subtitle={value.subtitle}
                                                    description={value.description}
                                                    descriptionExpand={value.descriptionExpand}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* End Program Area */}

            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper pb--120 bg_color--secondary">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{t("service.title1")}<br/> {t("service.title2")}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            {TrainersList.map((value, index) => (
                                <Trainer key={index} name={value.name} position={value.position}
                                         image={value.image}
                                />
                            ))}
                        </div>

                        <div className="row row--35">
                            <div className="experiences">
                                <h2 className="title">{t("service.experiences.title")}</h2>
                            </div>
                        </div>

                        <div className="services-images">
                            <img className="rellax--services-left-animate services-image left"
                                 src={`/assets/images/services/services_left.png`}
                                 loading="lazy" alt="services1"/>
                            <img className="rellax--services-middle-animate services-image middle"
                                 src={`/assets/images/services/services_middle.png`}
                                 loading="lazy" alt="services2"/>
                            <img className="rellax--services-right-animate services-image right"
                                 src={`/assets/images/services/services_right.png`}
                                 loading="lazy" alt="services3"/>
                        </div>
                    </div>

                    <div className="container pt--110">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="about-inner inner">
                                    <div className="section-title border-bottom">
                                        <span className="subtitle">{t("service.facilities.title")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row--35">
                            <div className="col-lg-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{t("service.facilities.subtitle1")}<br/> {t("service.facilities.subtitle2")}
                                            <br/>{t("service.facilities.subtitle3")}<br/> {t("service.facilities.subtitle4")}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row backgrounds">
                            {BackgroundsList.map((value, index) => (
                                <ServiceBackground key={index} place={value.place} activity={value.activity}
                                                   image={value.image}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

            <div className="bg_color--secondary">
                {/* Start Visit us Area  */}
                <div id="visitUs" className="fix">
                    <div className="visit-us-area creative-service-wrapper pb--120 bg_color--transparent">

                        <div className="bg_image bg_image--2 text-center">
                            <h2 className="title">{t("visit_us.title")}</h2>

                            <div className="why-to-join-items">
                                <div data-aos="fade-in" data-aos-duration="400"
                                     data-aos-easing="ease-in-sine" className="why-to-join-item">
                                    <img className="elipse" src={`/assets/images/icons/elipse.png`}
                                         loading="lazy" alt="elipse"/>
                                    <svg width="101.965" height="99.801" viewBox="0 0 101.965 99.801">
                                        <g id="check-circle" transform="translate(3.509 3.561)">
                                            <path id="Path_779" data-name="Path 779"
                                                  d="M90.677,42.281V46.36A44.339,44.339,0,1,1,64.385,5.835"
                                                  transform="translate(0 0)" fill="none" stroke="#fff"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round" strokeWidth="11"/>
                                            <path id="Path_780" data-name="Path 780" d="M66.64,4,22.3,48.383,9,35.081"
                                                  transform="translate(24.037 6.889)" fill="none" stroke="#fff"
                                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="11"/>
                                        </g>
                                    </svg>
                                    <p>{t("visit_us.item1")}</p>
                                </div>

                                <div data-aos="fade-in" data-aos-duration="400" data-aos-delay="200"
                                     data-aos-easing="ease-in-sine" className="why-to-join-item">
                                    <img className="elipse" src={`/assets/images/icons/elipse.png`}
                                         loading="lazy" alt="elipse"/>
                                    <svg width="94.339" height="95.663" viewBox="0 0 94.339 95.663">
                                        <path id="thumbs-down"
                                              d="M53.114,31.631V14.7A12.7,12.7,0,0,0,40.415,2L23.483,40.1V86.661H71.231a8.466,8.466,0,0,0,8.466-7.2l5.842-38.1a8.466,8.466,0,0,0-8.466-9.736ZM23.483,86.661H12.18a9.778,9.778,0,0,1-9.863-8.466V48.563A9.778,9.778,0,0,1,12.18,40.1h11.3"
                                              transform="translate(3.183 3.5)" fill="none" stroke="#fff"
                                              strokeLinecap="round" strokeLinejoin="round" strokeWidth="11"/>
                                    </svg>

                                    <p>{t("visit_us.item2")}</p>
                                </div>

                                <div data-aos="fade-in" data-aos-duration="400" data-aos-delay="400"
                                     data-aos-easing="ease-in-sine" className="why-to-join-item">
                                    <img className="elipse" src={`/assets/images/icons/elipse.png`}
                                         loading="lazy" alt="elipse"/>
                                    <svg width="82.704" height="93.855" viewBox="0 0 82.704 93.855">
                                        <path id="chevrons-up"
                                              d="M74.148,39.574,40.574,6,7,39.574m67.148,47L40.574,53,7,86.577"
                                              transform="translate(0.778 -0.5)" fill="#1b1b1b" stroke="#fff"
                                              strokeLinecap="round" strokeLinejoin="round" strokeWidth="11"/>
                                    </svg>
                                    <p>{t("visit_us.item3")}</p>
                                </div>
                            </div>

                            <div data-aos="fade-in" data-aos-duration="400"
                                 data-aos-easing="ease-in-sine" className="slide-btn mt--30"><a
                                className="btn-default btn-border btn-opacity"
                                onClick={navigateTo('application')}>{t("visit_us.btn")}</a></div>
                        </div>

                        <div className="container pt--110">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title border-bottom">
                                            <span className="subtitle">{t("why_tcs.title")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row--35">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title text-center">
                                            <h2 className="title">{t("why_tcs.subtitle1")}<br/> {t("why_tcs.subtitle2")}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row row--35 why-tcs">
                                <img className="rellax--why-tcs-image-left-animate why-tcs-image left"
                                     src={`/assets/images/visit-us/why-tsc_2.jpg`}
                                     loading="lazy" alt="whyTcs1"/>

                                <div className="col-lg-4"/>

                                <div className="col-lg-8">
                                    <div className="row row--35">
                                        <div className="col-lg-8 pt--110">
                                            <div className="about-inner inner">
                                                <h4>{t("why_tcs.about.title1")}</h4>
                                                <p>
                                                    {t("why_tcs.about.description")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <img className="rellax--why-tcs-image-right-animate why-tcs-image right"
                                                 src={`/assets/images/visit-us/why-tsc-3_2.jpg`}
                                                 loading="lazy" alt="whyTcs3"/>
                                        </div>
                                    </div>
                                    <img className="rellax--why-tcs-image-middle-animate why-tcs-image middle"
                                         src={`/assets/images/visit-us/why-tsc-2_2.jpg`}
                                         loading="lazy" alt="whyTcs2"/>
                                </div>
                            </div>

                            <div className="row row--35 why-tcs pt--110">
                                <div className="col-lg-4">
                                    <h4 className="title">
                                        {t("why_tcs.about.title2")}
                                    </h4>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Start Application Area  */}
                <div id="application" className="fix">
                    <div className="application-area pb--120 bg_color--transparent">
                        <div className="container pt--110">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title border-bottom">
                                            <span className="subtitle">{t("application.title")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row--35">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{t("application.subtitle1")}<br/> {t("application.subtitle2")}
                                                <br/> {t("application.subtitle3")}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="applications pt--80">
                                {ApplicationList.map((value, index) => (

                                        <Application t={t} key={index} date={value.date} description={value.description}
                                                     descriptionItems={value.descriptionItems}
                                        />
                                ))}
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="btn-default"
                                           target="_blank"
                                           href="https://forms.gle/hSeSs9LmzGZNuiBe9"><span>{t("visit_us.btn")}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Application Area  */}

                {/* Start Partners Area  */}
                <div id="partners" className="fix">
                    <div className="partners-area pb--120 bg_color--transparent">
                        <div className="container pt--110">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title border-bottom">
                                            <span className="subtitle">{t("partners.title")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row--35">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                                {t("partners.subtitle1")}<br/> {t("partners.subtitle2")}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="partners pt--40 pb--180">
                                {PartnersList.map((value, index) => (
                                    <div key={index} className="partner-image">
                                        <img width={value.width ? value.width : 100}
                                             src={`/assets/images/partners2/${value.image}`}
                                             loading="lazy" alt={value.name}/>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Partners Area  */}
            </div>

            <FooterTwo t={t}/>
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default PortfolioLanding;
